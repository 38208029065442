import React, { useState, useEffect, useRef } from 'react';
// import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
// import API from '../utils/apiCalling';
import API from '../../utils/apiCalling';
// import { config } from '../../utils/apiUrl';
import { toast } from 'react-toastify';
import './surveyWiseResponce.css'
import { getLocalDataAsObject } from '../../utils/CustomLocalStorage';
import Header from '../../Components/ReusableComponents/Header';
import Footer from '../../Components/ReusableComponents/Footer';
import { Redirect, Link, useHistory } from "react-router-dom";
// import ShowSurveyModal from '../common/showSurveyModal';
// import { confirmAlert } from 'react-confirm-alert'; // Import
// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import Loader2 from '../../utils/loader2/loader2';
// import LogicSurveyModal from '../common/logicSurveyModal'
// import EditSurveyModal from '../common/editSurveyModal';
// import SendByEmailModal from '../common/sendByEmailModal';
// import SendBySmsModal from '../common/sendBySmsModal';
import { CSVLink } from "react-csv";
// import Moment from 'react-moment';
import Navbar from '../paytm/navbar';
import Config from '../../config/env'
import Loader from '../../utils/loader';




const SurveyWiseResponse = (props) => {
    const api = new API()
    let optionsArr = []
    let history = new useHistory()
    const csvLink = useRef()

    let adminToken = getLocalDataAsObject("adminToken")
    if (!adminToken) {
        history.push("/")
    }



    const [allSurvey, setAllSurvey] = useState([])

    const [loader2, setLoader2] = useState(true)
    const [showSurveyWiseResponse, setShowSurveyWiseResponse] = useState(false)
    const [surveyResponse, setSurveyResponse] = useState()
    const [surveyWiseUserResponse, setSurveyWiseUserResponse] = useState()
    const [viewSurveyId, setViewSurveyId] = useState()
    const [downloadSurvey, setDownloadSurvey] = useState([])

    const [generate, setGenerate] = useState(false)
    const [excelFileName, setExcelFileName] = useState("")
    const [generateSurveyResponseFullDetails, setGenerateSurveyResponseFullDetails] = useState(false)
    const [responseFullDetailsHeader, setResponseFullDetailsHeader] = useState([])
    const [responseFullDetailsData, setResponseFullDetailsData] = useState([])

    const [filterQuestionsForExcel, setFilterQuestionsForExcel] = useState([])
    const [issOnlyResponseSurvey, setIsOnlyResponseSurvey] = useState(false)
    const [surveyForExcelHeaders, setSurveyForExcelHeaders] = useState()
    const [surevyHeaderNew, setSurveyHeaderNew] = useState()
    const [surveyType, setSurveyType] = useState()
    const [surveyStartDate, setSurveyStartDate] =  useState('')
    const [surveyEndDate, setSurveyEndDate] =  useState('')


    useEffect(() => {
        let adminToken = getLocalDataAsObject("adminToken")
        if (!adminToken) {
        }
        getAllSurvey()

    }, [])
    const getAllSurvey = async () => {
        let result = await api.post(`${Config.new.site_api}/admin-dashboard/getAllSurvey`, "data")
        if (result && result.code === 200) {
            setAllSurvey(result.data)
            setLoader2(false)
        }
    }

    const getSurveyWiseResponse = async (id) => {
        setLoader2(true)
        let data = {
            survey_id: id
        }
        let result = await api.post(`${Config.new.site_api}/admin-dashboard/getSurveyWiseResponse`, data)
        if (result && result.code === 200) {

            setSurveyWiseUserResponse(result.data)
            setLoader2(false)
        }
    }

    const getSurveyBy_Id = async (id) => {
        setLoader2(true)
        let data = {
            survey_id: id
        }
        let result = await api.post(`${Config.new.site_api}/survey/getSurveyById`, data)
        if (result && result.code === 200) {

            (result.data[0].type == "matrix") ? setSurveyType("matrix") : setSurveyType("normal");

            (result.data[0].type == "matrix") ? setSurveyForExcelHeaders(result.data[0]) : setSurveyForExcelHeaders(result.data)
            setSurveyForExcelHeaders(result.data)

            setLoader2(false)
        }
    }

    const getSurveyWiseOnlyResponse = async (id) => {
        setLoader2(true)
        let data = {
            survey_id: id
        }
        let result = await api.post(`${Config.new.site_api}/admin-dashboard/getSurveyWiseOnlyResponse`, data)
        
        if (result ) {

            setSurveyWiseUserResponse(result)
            setLoader2(false)
        }
    }

    const showSurveyWiseResponseBtn = (showSurvey) => {
        setShowSurveyWiseResponse(!showSurveyWiseResponse)
        setSurveyResponse(showSurvey)
        getSurveyWiseResponse(showSurvey._id)
        getSurveyBy_Id(showSurvey._id)
    }

    const showSurveyWiseResponseBtnOnlyResponse = (showSurvey) => {
        setShowSurveyWiseResponse(!showSurveyWiseResponse)
        setSurveyResponse(showSurvey)
        getSurveyWiseOnlyResponse(showSurvey._id)
        getSurveyBy_Id(showSurvey._id)
    }

    const downloadSurveyResponseExcel = (survey) => {
        setExcelFileName(survey.survey_name)
        setDownloadSurvey(surveyWiseUserResponse)
        setViewSurveyId(surveyResponse._id)
        setGenerate(true)
    }




    const downloadSurveyResponseFullDetails = async () => {
        alert("genrate excel")

        let EmailFilter = []
        surveyWiseUserResponse.filter((emailFilter) => {

            EmailFilter.push(emailFilter.email)
        })
        let data = {
            emails: JSON.stringify(EmailFilter),
            surveyId: surveyResponse._id
        }

        let filterResponse
        let result = await api.post(`${Config.new.site_api}/admin-dashboard/surveyWiseResponseFullDetails`, data)
        if (result && result.code === 200) {

            filterResponse = [{ label: "Response id", key: "_id" },
            { label: "Email", key: "email" },
            { label: "Survey Name", key: "surveyName" },
            { label: "Survey Id", key: "surveyId" },
            { label: "Survey Stutas", key: "surveyStatus" },
            { label: "Survey Type", key: "surveyType" },
            { label: "Survey Response Date", key: "date" }]

            if (surveyType === "matrix") {
                surveyForExcelHeaders && (surveyForExcelHeaders[0].questions[0]).map((que, index) => {
                    filterResponse.push({ label: que.question, key: que.question })
                })
            } else {
                surveyForExcelHeaders && (surveyForExcelHeaders[0].questions).map((que, index) => {
                    let sameHeader = false
                    let obj1 = JSON.stringify({ label: que.question, key: que.question })
                    filterResponse.forEach(res => {
                        let obj = JSON.stringify(res)

                        if (obj == obj1) {
                            sameHeader = true
                        }
                    })
                    if (!sameHeader) {
                        filterResponse.push({ label: que.question, key: que.question })
                    }
                })
            }

            if (result && result.data) {

                (result.data).map((val) => {

                    if (val.attendedSurvey.length > 0) {
                        console.log(val, "valllllllllllll")
                        let filterData = {
                            _id: val._id,
                            email: val.email,
                            surveyName: val.attendedSurvey && val.attendedSurvey[0] && val.attendedSurvey[0].surveyName,
                            surveyId: val.attendedSurvey && val.attendedSurvey[0] && val.attendedSurvey[0].surveyId,
                            surveyStatus: val.attendedSurvey && val.attendedSurvey[0] && val.attendedSurvey[0].surveyStatus,
                            surveyType: val.attendedSurvey && val.attendedSurvey[0] && val.attendedSurvey[0].surveyType,
                            date: val.attendedSurvey && val.attendedSurvey[0] && val.attendedSurvey[0].date,
                        }
                        if (surveyType === "matrix") {
                            val.attendedSurvey && val.attendedSurvey[0] && (val.attendedSurvey[0].questions).map((que, index) => {
                                filterData[que.questions] = que.answer
                            })
                        } else {
                            val.attendedSurvey && val.attendedSurvey[0] && (val.attendedSurvey[0].questions).map((que, index) => {
                                filterData[que.question] = filterData[que.question] ? filterData[que.question] : que.answer
                            })
                        }
                        responseFullDetailsData.push(filterData)
                    }
                })
            }
        }
        setSurveyHeaderNew(filterResponse)
        setGenerateSurveyResponseFullDetails(true)
    }

    const downloadSurveyResponseFullDetailsTestttttttt = async () => {
        

        downloadSurveyResponseFullDetails()
        setGenerateSurveyResponseFullDetails(true)


    }

    const convertSecondsToMinutesAndSeconds = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}.${remainingSeconds}`;
    }

    const clearDateFilter = () => {
        setSurveyStartDate("")
        setSurveyEndDate("")
    }
    const downloadSurveyOnlyResponseFullDetails = async () => {
        alert("generate excel");
      
        let data = {
          survey_id: surveyResponse._id,
          surveyStartDate : surveyStartDate,
          surveyEndDate : surveyEndDate
        };
      setLoader2(true)
        try {
          const response = await fetch(`${Config.new.site_api}/admin-dashboard/getSurveyWiseOnlyResponse`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          });
      
          if (response.ok) {
            setLoader2(false)
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'survey_responses.xlsx';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          } else {
            console.error('Failed to download file');
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };
      
    // const downloadSurveyOnlyResponseFullDetails = async () => {
    //     alert("generate excel");
      
    //     let data = {
    //       survey_id: surveyResponse._id
    //     };
      
    //     let filterResponse = [];
    //     let result = [];
    //     const responseFullDetailsData = [];
      
    //     try {
    //       let response = await fetch(`${Config.new.site_api}/admin-dashboard/getSurveyWiseOnlyResponse`, {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify(data)
    //       });
      
    //       const reader = response.body.getReader();
    //       const decoder = new TextDecoder();
    //       let partialData = '';
      
    //       while (true) {
    //         const { done, value } = await reader.read();
    //         if (done) break;
    //         partialData += decoder.decode(value, { stream: true });
      
    //         // Process each chunk as it arrives
    //         let chunk;
    //         try {
    //           chunk = JSON.parse(partialData);
    //           result.push(...chunk);
    //           partialData = '';
    //         } catch (e) {
    //           // Wait for more data to complete JSON
    //         }
    //       }
      
    //       console.log(result);
      
    //       filterResponse = [
    //         { label: "Response id", key: "ResponceId" },
    //         { label: "Survey Name", key: "surveyName" },
    //         { label: "Survey Id", key: "surveyId" },
    //         { label: "Survey Status", key: "surveyStatus" },
    //         { label: "Survey Type", key: "surveyType" },
    //         { label: "Survey Response Date", key: "date" },
    //         { label: "Share Id", key: "shareId" },
    //         { label: "LOI", key: "LOI" },
    //         { label: "Quality Status", key: "qualityStatus" },
    //       ];
      
    //       if (surveyType === "matrix") {
    //         surveyForExcelHeaders && (surveyForExcelHeaders[0].questions[0]).map((que, index) => {
    //           filterResponse.push({ label: que.question, key: que.question });
    //         });
    //       } else {
    //         surveyForExcelHeaders && (surveyForExcelHeaders[0].questions).map((que, index) => {
    //           let sameHeader = false;
    //           let obj1 = JSON.stringify({ label: que.question, key: que.question });
    //           filterResponse.forEach(res => {
    //             let obj = JSON.stringify(res);
    //             if (obj == obj1) {
    //               sameHeader = true;
    //             }
    //           });
    //           if (!sameHeader) {
    //             filterResponse.push({ label: que.question, key: que.question });
    //           }
    //         });
    //       }
      
    //       result.map((val) => {
    //         let filterData = {
    //           ResponceId: val._id,
    //           surveyName: val.surveyStatus && val.surveyName,
    //           surveyId: val.surveyStatus && val.surveyId,
    //           surveyStatus: val.surveyStatus && val.surveyStatus,
    //           surveyType: val.surveyStatus && val.surveyType,
    //           date: val.surveyStatus && val.date,
    //           shareId: val.surveyStatus && val.shareId,
    //           LOI: convertSecondsToMinutesAndSeconds(val.loi),
    //           qualityStatus: val.surveyStatus && val.qualityStatus ? val.qualityStatus : 'unCheck',
    //         };
      
    //         if (surveyType === "matrix") {
    //           val.questions.map((que, index) => {
    //             filterData[que.questions] = que.answer;
    //           });
    //         } else {
    //           val.questions.map((que, index) => {
    //             filterData[que.question] = filterData[que.question] ? filterData[que.question] : que.answer;
    //           });
    //         }
      
    //         responseFullDetailsData.push(filterData);
    //       });
      
    //       surveyForExcelHeaders.push(filterResponse);
    //       setGenerateSurveyResponseFullDetails(true);
    //     } catch (error) {
    //       console.error('Error:', error);
    //     }
    //     setGenerateSurveyResponseFullDetails(true);
    //   };
      


//     const downloadSurveyOnlyResponseFullDetails = async () => {
//         alert("genrate excel")

//         let data = {
//             survey_id: surveyResponse._id
//         }
//         let filterResponse
// let result={}
//         let results = await api.post(`${Config.new.site_api}/admin-dashboard/getSurveyWiseOnlyResponse`, data)
        
//         if (true) {
//             result.data=results
//             console.log(results)
//             filterResponse = [{ label: "Response id", key: "ResponceId" },
//             { label: "Survey Name", key: "surveyName" },
//             { label: "Survey Id", key: "surveyId" },
//             { label: "Survey Status", key: "surveyStatus" },
//             { label: "Survey Type", key: "surveyType" },
//             { label: "Survey Response Date", key: "date" },
//             { label: "Share Id", key: "shareId" },
//             { label: "LOI", key: "LOI" },
//             { label: "Quality Status", key: "qualityStatus" },
//             ]

//             if (surveyType === "matrix") {

//                 surveyForExcelHeaders && (surveyForExcelHeaders[0].questions[0]).map((que, index) => {
//                     filterResponse.push({ label: que.question, key: que.question })
//                 })
//             } else {
//                 surveyForExcelHeaders && (surveyForExcelHeaders[0].questions).map((que, index) => {
//                     let sameHeader = false
//                     let obj1 = JSON.stringify({ label: que.question, key: que.question })
//                     filterResponse.forEach(res => {
//                         let obj = JSON.stringify(res)
//                         if (obj == obj1) {
//                             sameHeader = true
//                         }
//                     })
//                     if (!sameHeader) {
//                         filterResponse.push({ label: que.question, key: que.question })
//                     }
//                 })
//             }

//             (result.data && result.data.length > 0 && result.data).map((val) => {

//                 // calculatedLoi
//                 let filterData =
//                 {
//                     ResponceId: val._id,
//                     surveyName: val.surveyStatus && val.surveyName,
//                     surveyId: val.surveyStatus && val.surveyId,
//                     surveyStatus: val.surveyStatus && val.surveyStatus,
//                     surveyType: val.surveyStatus && val.surveyType,
//                     date: val.surveyStatus && val.date,
//                     shareId: val.surveyStatus && val.shareId,
//                     LOI: convertSecondsToMinutesAndSeconds(val.loi),
//                     qualityStatus: val.surveyStatus && val.qualityStatus ? val.qualityStatus : 'unCheck',
//                 }


                
//                 if (surveyType === "matrix") {
//                     val.questions.map((que, index) => {

//                         filterData[que.questions] = que.answer
//                     })
//                 } else {
//                     val.questions.map((que, index) => {

//                         filterData[que.question] = filterData[que.question] ? filterData[que.question] : que.answer

//                     })
//                 }

//                 responseFullDetailsData.push(filterData)

//             })


//             surveyForExcelHeaders.push(filterResponse)
//             setGenerateSurveyResponseFullDetails(true)
//         }
//         setGenerateSurveyResponseFullDetails(true)

//     }
    let filterResponse = (val) => {
        let newArr = []
        val.attendedSurvey && val.attendedSurvey[0] && (val.attendedSurvey[0].questions).map((que) => {

            let filterOptions = []
            que.options.map(op => {
                filterOptions.push(op.option)
            })
            let filterQue = {
                questions: que.questions,
                answer: que.answer,
                textAnswer: que.textVal,
                options: filterOptions

            }
            newArr.push(JSON.stringify(filterQue))
            filterQue = {}
        })
        return newArr
    }
    let filterResponse1 = (val) => {
        let newArr = []
        let indexCount = 1
        val.attendedSurvey && val.attendedSurvey[0] && (val.attendedSurvey[0].questions).map((que, index) => {
            newArr.push(
                que.answer
            )
        })

        return newArr
    }

    const setQualityStatus = async (surveyId, status) => {
        let body = {
            survey_id: surveyId,
            status: status
        }
        let result = await api.post(`${Config.new.site_api}/admin-dashboard/responseQualityCheck`, body)
        if (result.code == 200) {
            const newResponseFullDetailsData = responseFullDetailsData.map((e) => {
                if (e.ResponceId == surveyId) {
                    console.log('aaya')
                    e.qualityStatus = status
                }
                return e
            })
            setResponseFullDetailsData(newResponseFullDetailsData)
        }
    }

    return (
        <>
       
            {/* <Header {...props} />  */}

            <div>

                {/* <Tabs {...props}/> */}

                <div className="container-fluid mt-5" style={{ marginTop: "120px" }}>

                    <div style={{ marginLeft: "0%" }}>
                        <div className="w3-container">
                            <h3 style={{ marginLeft: "35%" }}>DoYourSurvey Survey Panel Dashboard</h3>
                        </div>
                        {loader2 == true
                            ?
                            <div style={{ marginTop: "10%" }}>
                                <Loader2 />
                            </div>
                            :
                            <div>
                                {issOnlyResponseSurvey == false &&
                                    <div>
                                        {showSurveyWiseResponse == false ?
                                            <div>
                                                <h3 align="center">List of All Survey</h3>

                                                <div className="container" style={{ marginLeft: "5%" }}>
                                                    <span><button onClick={() => setIsOnlyResponseSurvey(false)} className="w3-bar-item w3-button mb-1" style={{ backgroundColor: "gray", margin: "5px", color: "white", position: "sticky" }}>Panel Survey</button></span>
                                                    <span> <button onClick={() => setIsOnlyResponseSurvey(true)} className="w3-bar-item w3-button " style={{ backgroundColor: "gray", margin: "5px", color: "white" }}>Direct Survey Only Response</button></span>
                                                </div>

                                                {allSurvey.map((allsurvey, index) => (
                                                    <div className="container col-md-11 mt-4">

                                                        <div className="card ">
                                                            <div className="card card-header">
                                                                <h6>{index + 1}  Survey Name : <span style={{ color: "#6f4e37" }}>{allsurvey.survey_name}</span>
                                                                    {allsurvey.status == "active" ? <span className="onlive" >Active</span>
                                                                        : <>
                                                                            <span className="offlive">InActive</span>

                                                                        </>}
                                                                </h6>
                                                            </div>
                                                            <div className="card card-body">
                                                                <h6> Survey Point : {allsurvey.points}</h6>
                                                                <h6 className="text-capitalize"> Survey Status : {allsurvey.status}</h6>
                                                                <h6 className="text-capitalize"> Survey Type : {allsurvey.survey_type}</h6>
                                                                <h6 className="text-capitalize"> Survey Id : {allsurvey._id}</h6>
                                                                {/* <h6> Survey created Date : 11-1-2121</h6> */}
                                                            </div>
                                                            <div className="card-footer">
                                                                <button onClick={() => showSurveyWiseResponseBtn(allsurvey)} className="btn btn-outline-primary" style={{ fontSize: "14px" }}>Survey Wise Response</button>


                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            <div className="container col-md-10 mt-4">
                                                <h4 align="center">Show Survey Wise Response</h4>
                                                <div className="container" style={{ marginLeft: "5%" }}>
                                                    <span><button onClick={() => setIsOnlyResponseSurvey(false)} className="w3-bar-item w3-button mb-1" style={{ backgroundColor: "gray", margin: "5px", color: "white", position: "sticky" }}>Panel Survey</button></span>
                                                    <span> <button onClick={() => setIsOnlyResponseSurvey(true)} className="w3-bar-item w3-button " style={{ backgroundColor: "gray", margin: "5px", color: "white" }}>Direct Survey Only Response</button></span>
                                                </div>
                                                <div className="container col-md-12 mt-4">

                                                    <div className="card">
                                                        <div className="card card-header">
                                                            <h6>  Survey Name : <span style={{ color: "#6f4e37" }}>{surveyResponse.survey_name}</span>
                                                                {surveyResponse.status == "active" ? <span className="onlive" >Active</span>
                                                                    : <>
                                                                        <span className="offlive">InActive</span>
                                                                    </>}
                                                            </h6>
                                                        </div>
                                                        <div className="card card-body">
                                                            <h6> Survey Point : {surveyResponse.points}</h6>
                                                            <h6 className="text-capitalize"> Survey Status : {surveyResponse.status}</h6>
                                                            <h6 className="text-capitalize"> Survey Id : {surveyResponse._id}</h6>
                                                            <h6 className="text-capitalize"> Total Survey Response : {surveyWiseUserResponse && surveyWiseUserResponse.length}</h6>
                                                            {/* <h6> Survey created Date : 11-1-2121</h6> */}
                                                            <div className="card card-sm">
                                                                <div className="card-header">
                                                                    <div>
                                                                        <h5 style={{ display: "inline" }}>Survey Attended User Response</h5>
                                                                        {
                                                                            surveyWiseUserResponse && surveyWiseUserResponse.length > 0
                                                                                &&
                                                                                generate && viewSurveyId == surveyResponse._id ?
                                                                                <CSVLink color="black" data={downloadSurvey}
                                                                                    filename={"surveyWiseUserResponse" + "=" + excelFileName + ".csv"} ref={csvLink}>
                                                                                    <button className="btn btn-success ml-3 float-right">Excel Download</button>
                                                                                </CSVLink>
                                                                                :
                                                                                <button disabled={surveyWiseUserResponse && surveyWiseUserResponse.length == 0} onClick={() => downloadSurveyResponseExcel(surveyResponse)} className="btn btn-secondary ml-3 float-right">Generate Excel</button>

                                                                        }
                                                                        {
                                                                            generateSurveyResponseFullDetails ?
                                                                                // <CSVLink color="black" data={responseFullDetailsData} headers={responseFullDetailsHeader}
                                                                                //     filename={"surveyWiseUserResponseFullDetails.csv"} ref={csvLink}>
                                                                                //     <button className="btn btn-success ml-3 float-right">Download survey Response Details</button>
                                                                                // </CSVLink>
                                                                                <>

                                                                                    <CSVLink color="black" data={responseFullDetailsData} headers={surevyHeaderNew}
                                                                                        filename={"surveyWiseUserResponseFullDetails.csv"} >
                                                                                        <button className="btn btn-success ml-3 float-right">Download survey Response Details</button>
                                                                                    </CSVLink>
                                                                                </>
                                                                                :
                                                                                <button onClick={() => downloadSurveyResponseFullDetailsTestttttttt(surveyResponse)} className="btn btn-secondary ml-3 float-right">generate excel survey response</button>
                                                                        }

                                                                    </div>
                                                                    <div className="card-body col-md-12" style={{ overflow: "auto", maxHeight: "300px" }}>
                                                                        {surveyWiseUserResponse && surveyWiseUserResponse.length > 0
                                                                            ? <div>
                                                                                {surveyWiseUserResponse && surveyWiseUserResponse.map((response, ind) => (
                                                                                    <div className="row" style={{ border: "1px solid" }}>
                                                                                        <div className="col-md-5" >
                                                                                            <h6>{ind + 1} {response && response.email}</h6>
                                                                                        </div>
                                                                                        <div className="col-md-2">
                                                                                            <h6>{response && response.city}</h6>
                                                                                        </div>
                                                                                        <div className="col-md-2">
                                                                                            <h6>{response && response.surveyType}</h6>
                                                                                        </div>
                                                                                        <div className="col-md-2">
                                                                                            <h6>{response && response.surveyStatus}</h6>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                            :
                                                                            <h4 className="text-danger">No response yet</h4>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-footer">
                                                            <button onClick={() => setShowSurveyWiseResponse(false)} className="btn btn-outline-primary" style={{ fontSize: "14px" }}>Go Back</button>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }

                                {issOnlyResponseSurvey == true &&

                                    <div>
                                        {showSurveyWiseResponse == false ?
                                            <div>
                                                <h3 align="center">List of All Survey</h3>

                                                <div className="container" style={{ marginLeft: "5%" }}>
                                                    <span><button onClick={() => setIsOnlyResponseSurvey(false)} className="w3-bar-item w3-button mb-1" style={{ backgroundColor: "gray", margin: "5px", color: "white", position: "sticky" }}>Panel Survey</button></span>
                                                    <span> <button onClick={() => setIsOnlyResponseSurvey(true)} className="w3-bar-item w3-button " style={{ backgroundColor: "gray", margin: "5px", color: "white" }}>Direct Survey Only Response</button></span>
                                                </div>
                                                {allSurvey.map((allsurvey, index) => (
                                                    <div className="container col-md-9 mt-4">
                                                        {/* ______________________________ */}
                                                        {allsurvey.survey_type == "direct" &&
                                                            <div className="card ">
                                                                <div className="card card-header">
                                                                    <h6>{index + 1}  Survey Name : <span style={{ color: "#6f4e37" }}>{allsurvey.survey_name}</span>
                                                                        {allsurvey.status == "active" ? <span className="onlive" >Active</span>
                                                                            : <>
                                                                                <span className="offlive">InActive</span>

                                                                            </>}
                                                                    </h6>
                                                                </div>
                                                                <div className="card card-body">
                                                                    <h6> Survey Point : {allsurvey.points}</h6>
                                                                    <h6 className="text-capitalize"> Survey Status : {allsurvey.status}</h6>
                                                                    <h6 className="text-capitalize"> Survey Type : {allsurvey.survey_type}</h6>
                                                                    <h6 className="text-capitalize"> Survey Id : {allsurvey._id}</h6>
                                                                    {/* <h6> Survey created Date : 11-1-2121</h6> */}
                                                                </div>
                                                                <div className="card-footer">
                                                                    <button onClick={() => showSurveyWiseResponseBtnOnlyResponse(allsurvey)} className="btn btn-outline-primary" style={{ fontSize: "14px" }}>Survey Wise Response</button>


                                                                </div>
                                                            </div>
                                                        }
                                                        {/* _________ */}
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            <div className="container col-md-10 mt-4">
                                                <h4 align="center">Show Survey Wise Response</h4>
                                                <div className="container" style={{ marginLeft: "5%" }}>
                                                    <span><button onClick={() => setIsOnlyResponseSurvey(false)} className="w3-bar-item w3-button mb-1" style={{ backgroundColor: "gray", margin: "5px", color: "white", position: "sticky" }}>Panel Survey</button></span>
                                                    <span> <button onClick={() => setIsOnlyResponseSurvey(true)} className="w3-bar-item w3-button " style={{ backgroundColor: "gray", margin: "5px", color: "white" }}>Direct Survey Only Response</button></span>
                                                </div>
                                                <div className="container col-md-12 mt-4">

                                                    <div className="card">
                                                        <div className="card card-header">
                                                            <h6>  Survey Name : <span style={{ color: "#6f4e37" }}>{surveyResponse.survey_name}</span>
                                                                {surveyResponse.status == "active" ? <span className="onlive" >Active</span>
                                                                    : <>
                                                                        <span className="offlive">InActive</span>
                                                                    </>}
                                                            </h6>
                                                        </div>
                                                        <div className="card card-body">
                                                               <div  className='d-flex'>
                                                               <h6 className=''>Select Date :-  </h6>   <input type='date' className='form-control w-25 mt-1 mx-3' value={surveyStartDate} onChange={(e)=>setSurveyStartDate(e.target.value)}/>
                                                               <h6 className=''>to  </h6>   <input type='date' className='form-control w-25 mt-1 mx-3' value={surveyEndDate} onChange={(e)=>setSurveyEndDate(e.target.value)}/>
                                                               <button onClick={()=>clearDateFilter()} className='mainbtn'>Clear</button>
                                                               </div>
                                                            <h6> Survey Point : {surveyResponse.points}</h6>
                                                            <h6 className="text-capitalize"> Survey Status : {surveyResponse.status}</h6>
                                                            <h6 className="text-capitalize"> Survey Id : {surveyResponse._id}</h6>
                                                            <h6 className="text-capitalize"> Total Survey Response : {surveyWiseUserResponse && surveyWiseUserResponse.length}</h6>
                                                            {/* <h6> Survey created Date : 11-1-2121</h6> */}
                                                            <div className="card card-sm">
                                                                <div className="card-header">
                                                                    <div>
                                                                        <h5 style={{ display: "inline" }}>Survey Attended User Response</h5>
                                                                        {/* {
                                                                        surveyWiseUserResponse && surveyWiseUserResponse.length > 0
                                                                            &&
                                                                            generate && viewSurveyId == surveyResponse._id ?
                                                                            <CSVLink color="black" data={downloadSurvey}
                                                                                filename={"surveyWiseUserResponse" + "=" + excelFileName + ".csv"} ref={csvLink}>
                                                                                <button className="btn btn-success ml-3 float-right">Excel Download</button>
                                                                            </CSVLink>
                                                                            :
                                                                            <button disabled={surveyWiseUserResponse && surveyWiseUserResponse.length == 0} onClick={() => downloadSurveyResponseExcel(surveyResponse)} className="btn btn-secondary ml-3 float-right">Generate Excel</button>

                                                                    } */}


                                                                        {
                                                                            generateSurveyResponseFullDetails ?
                                                                                <>

                                                                                    <CSVLink color="black" data={responseFullDetailsData} headers={surveyForExcelHeaders[1]}
                                                                                        filename={"surveyWiseUserResponseFullDetails.csv"} ref={csvLink}>
                                                                                        <button className="btn btn-success ml-3 float-right">Download survey Response Details</button>
                                                                                    </CSVLink>
                                                                                </>
                                                                                :
                                                                                <button onClick={() => downloadSurveyOnlyResponseFullDetails(surveyResponse)} className="btn btn-secondary ml-3 float-right">generate excel survey response</button>
                                                                        }
                                                                    </div>
                                                                    <div className="card-body col-md-12" style={{ overflow: "auto", maxHeight: "300px" }}>
                                                                        {responseFullDetailsData && responseFullDetailsData.length > 0
                                                                            ? <div>
                                                                                <div className="row" style={{ border: "1px solid" }}>
                                                                                    <div className="col-md-3" >
                                                                                        <h6>#  Response Id</h6>
                                                                                    </div>
                                                                                    <div className="col-md-3" >
                                                                                        <h6>Date & Time</h6>
                                                                                    </div>

                                                                                    <div className="col-md-2">
                                                                                        <h6>Survey Type</h6>
                                                                                    </div>
                                                                                    <div className="col-md-2">
                                                                                        <h6>Survey Status</h6>
                                                                                    </div>
                                                                                    <div className="col-md-2">
                                                                                        <h6>Quality Status</h6>
                                                                                    </div>
                                                                                </div>
                                                                                {responseFullDetailsData && responseFullDetailsData.map((response, ind) => (
                                                                                    <div className="row" style={{ border: "1px solid" }}>
                                                                                        <div className="col-md-3 .fs-6" >
                                                                                            <p>{ind + 1} {response && response.ResponceId}</p>
                                                                                        </div>
                                                                                        <div className="col-md-3" >
                                                                                            <h6> {response && response.date}</h6>
                                                                                        </div>

                                                                                        <div className="col-md-2">
                                                                                            <h6>{response && response.surveyType}</h6>
                                                                                        </div>
                                                                                        <div className="col-md-2">
                                                                                            <h6>{response && response.surveyStatus}</h6>
                                                                                        </div>
                                                                                        <div className="col-md-2">{(response?.qualityStatus == undefined || response?.qualityStatus == '' || response?.qualityStatus == 'unCheck') ?
                                                                                            <>
                                                                                                <button onClick={() => setQualityStatus(response.ResponceId, 'Pass')} className=' btn btn-success border border-light'>Pass</button>
                                                                                                <button onClick={() => setQualityStatus(response.ResponceId, 'Fail')} style={{ width: '58px' }} className='btn btn-danger border border-light w-5'>Fail  </button>
                                                                                            </> :
                                                                                            <h6>{response?.qualityStatus}</h6>}
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                            :
                                                                            <h4 className="text-danger">No response yet  </h4>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-footer">
                                                            <button onClick={() => setShowSurveyWiseResponse(false)} className="btn btn-outline-primary" style={{ fontSize: "14px" }}>Go Back</button>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
                {/* <Footer {...props} /> */}
            </div>
        </>
    );
}

export default SurveyWiseResponse;